import { Injectable, EventEmitter } from "@angular/core";

@Injectable({providedIn: 'root'})
export class ErrorSnackbarService {

    static errorEvent = new EventEmitter<any>();
 
    static showError(error: string) {
        ErrorSnackbarService.errorEvent.emit(error);
    }
}