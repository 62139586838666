import { Injectable } from '@angular/core';
import { Navigation, NavigationItem } from '../model/navigation';
import { IsfService } from './isf.service';


@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private navigationItems: Navigation[] = [];

  constructor(private _isfService: IsfService) { }

  async init() {
    await this._isfService.init();
    this.navigationItems.push(this.createItem('Dashboard','Dashboard','Dashboard','item', 'dashboard','/cora/dashboard'));
    this.navigationItems.push(await this.createItemWithChildren('projects-container', 'Proyectos', 'Proyectos', 'collapsable','accessibility_new', '/cora/projects'));
  }

  getNavigation(): Navigation[] {
    return this.navigationItems;
  }

  async createItemWithChildren(id: string, title: string, translate: string,
    type: 'item' | 'group' | 'collapsable', icon: string, url: string): Promise<NavigationItem>{

    return this._isfService.getAllProjectsIdentifiers().then(
      (projectsIdentifiers) => {
            let item = this.createItem(id, title, translate, type, icon, url);
            let children: NavigationItem[] = [];
            for (let projectIdentifier of projectsIdentifiers) {
              children.push(this.createItem(projectIdentifier.id + '-' + projectIdentifier.name, projectIdentifier.name, projectIdentifier.name,
                'item', icon, '/cora/project/' + projectIdentifier.name + '/' + projectIdentifier.id));
            }
            item.children = children;
            return item;
      }
    )
  }

  createItem(id: string, title: string, translate: string,
    type: 'item' | 'group' | 'collapsable', icon: string, url?: string): NavigationItem {

      let item: NavigationItem = {
            id       : id,
            title    : title,
            translate: translate,
            type     : type,
            icon     : icon,
    }

    if (url) {
        item.url = url
    }

    return item
  }
}
