import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { AuthService } from "./auth.service";

@Injectable({
    providedIn: 'root'
  })
export class IsSignedInGuard implements CanActivate {
    
    constructor(private authService: AuthService, public router: Router) {
    }
  
    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        const isLoggedIn = await this.authService.isLogin();
        if (isLoggedIn) {
            this.router.navigate(["/cora/projects"]);
            return false;
        }
        return true;
    }
}