// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  //Firebase config
  firebase_config: {
        "apiKey": "AIzaSyCWsUOD54-HJ0gQVl05EuHicrkjGME7I34",
        "authDomain": "isf-test-71b75.firebaseapp.com",
        "projectId": "isf-test-71b75",
        "storageBucket": "isf-test-71b75.appspot.com",
        "messagingSenderId": "1058038716268",
        "appId": "1:1058038716268:web:9649f3eccc7018fad1c549"
    },

  //Config APP
  config: {
    urlBE: 'https://voluntariado.isf-argentina.org/api/isf',
    urlWeb: 'https://voluntariado.isf-argentina.org'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
