import { Component, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import { Enrollment, IsfService, WorkSession } from '../../services/isf.service';
import { getDateDescription } from 'src/app/utils/utils';

export interface CarOption {
    value: string;
    viewValue: string;
}

export interface Diet {
    value: string;
    viewValue: string;
}

@Component({
  selector: 'form-stage-two',
  template: `
    <div class="introduction-text">
        <h3>Te pedimos que completes los siguientes datos para organizar la logística de la jornada.</h3>
    </div>
    <mat-card class="form-card">
        <h2>{{this.projectName}}</h2>
        <h4>Fecha y hora de la jornada:</h4>
        <h4>{{dateDescription(this.workSession.implementation_date)}}</h4>
        <form class="form-page">
            <mat-form-field class="input-select-stage2">
                <mat-label>¿Venís con auto propio?</mat-label>
                <div class="select-wrapper">
                <mat-select (selectionChange)="onCarSelectionChange($event)">
                    <mat-option *ngFor="let carOption of carOptions" [value]="carOption.value">
                    {{carOption.viewValue}}
                    </mat-option>
                </mat-select>
                <span matTooltip="Selecciona la opción 'Tengo auto disponible' si podes llevar personas a la jornada." matTooltipPosition="after">
                    <mat-icon class="question-icon">help_outline</mat-icon>
                </span>
                </div>
            </mat-form-field>


            <mat-form-field class="comment">
                <mat-label>¿Desde dónde salís?</mat-label>
                <textarea matInput #location placeholder="No es necesario poner tu dirección exacta, puede ser una intersección de calles"></textarea>
            </mat-form-field>

            <mat-form-field class="input-select-stage2">
                <mat-label>¿Tenes alguna dieta?</mat-label>
                <mat-select (selectionChange)="onDietSelectionChange($event)">
                    <mat-option *ngFor="let dietOption of dietOptions" [value]="dietOption.value">
                    {{dietOption.viewValue}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="comment">
              <mat-label>Detalles adicionales</mat-label>
              <textarea matInput #comments></textarea>
            </mat-form-field>

        </form>
    </mat-card>
    <div class="confirm-button">
        <button class="form-button" mat-flat-button (click)="continue()">Continuar</button>
    </div>
  `,
})
export class WorkSessionFormStageTwoComponent {
    @ViewChild('location') location: ElementRef;
    @ViewChild('comments') comments: ElementRef;

    enrollment: Enrollment;
    workSession: WorkSession;
    projectName = null;
    isNewContact: boolean;
    token: string;

    carOptions: CarOption[] = [
        {value: 'voy por mi cuenta', viewValue: 'voy por mi cuenta'},
        {value: 'necesito transporte', viewValue: 'necesito transporte'},
        {value: 'tengo auto disponible', viewValue: 'tengo auto disponible'},
      ];

    dietOptions: Diet[] = [
        {value: 'llevo vianda', viewValue: 'llevo vianda'},
        {value: 'no me quedo a comer', viewValue: 'no me quedo a comer'},
        {value: 'ninguna', viewValue: 'ninguna'},
        {value: 'celíaco/a', viewValue: 'celíaco/a'},
        {value: 'vegano/a', viewValue: 'vegano/a'},
        {value: 'vegetariano/a', viewValue: 'vegetariano/a'},
    ];

    constructor(
        private _isfService: IsfService,
        private _router: Router,
        private route: ActivatedRoute
    ) {}

    ngOnInit() {
        this.route.queryParams.subscribe(params => {
            if (params.enrollment) {
                this.enrollment = JSON.parse(params.enrollment);
            }
            this.projectName = params.projectName;
            this.workSession = JSON.parse(params.workSession);
            this.isNewContact = params.isNewContact;
            this.token = params.token;
        });
    }

    dateDescription(date: string) {
      return getDateDescription(date, true);
    }

    onCarSelectionChange(event: MatSelectChange) {
        this.enrollment.transport = event.value;
    }

    onDietSelectionChange(event: MatSelectChange) {
        this.enrollment.diet = event.value;
    }

    continue() {
        this.enrollment.location = this.location.nativeElement.value;
        this.enrollment.comments = this.comments.nativeElement.value;
        this._router.navigate(['/form-stage-three'], { queryParams: {
            enrollment: JSON.stringify(this.enrollment),
            isNewContact: this.isNewContact,
            workSession: JSON.stringify(this.workSession),
            token: this.token
        } });
    }
}
