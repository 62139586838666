import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Enrollment, IsfService, WorkSession } from 'src/app/services/isf.service';
import { getDateDescription, formatDateToShow } from 'src/app/utils/utils';

@Component({
  selector: 'form-stage-three',
  template: `
    <div class="introduction-text">
        <h3>A continuación te pedimos que valides los datos ingresados.</h3>
    </div>
    <mat-card class="form-card">
        <h2>Confirmación de datos</h2>

        <h3>Datos personales</h3>
        <p>Nombre y Apellido (pronombres): {{ enrollment.contact.name }} {{ enrollment.contact.lastname }} ({{ enrollment.contact.pronoun }})</p>
        <p>Fecha de nacimiento: {{  enrollment.contact.birthdate ? reverseFormatDate(enrollment.contact.birthdate) : '' }}</p>
        <p>DNI: {{ enrollment.contact.dni }}</p>
        <p>Email: {{ enrollment.contact.email }}</p>
        <p>Telefono: {{ enrollment.contact.phone }}</p>

        <h3>Datos para la jornada</h3>
        <p>Fecha y hora de la jornada: {{ dateDescription(workSession.implementation_date, true) }}</p>
        <p>Forma de transporte: {{ enrollment.transport }}</p>
        <p>Ubicación: {{ enrollment.location }}</p>
        <p>Dieta: {{ enrollment.diet }}</p>
        <p>Datos extra: {{ enrollment.comments }}</p>
        <p>{{ isNewContact ? 'Si más adelante' : 'Si faltan o '}} queres modificar alguno de estos datos comunicate con:</p>
        <p>voluntariado@isf-argentina.org</p>
    </mat-card>
    <div class="confirm-button">
        <button class="form-button" mat-flat-button (click)="confirm()">Confirmar</button>
    </div>
  `,
})
export class WorkSessionFormStageThreeComponent {
  enrollment: Enrollment;
  isNewContact: boolean;
  selectedWorkSessionId: string;
  workSession: WorkSession;
  token: string;

  constructor(
    private _isfService: IsfService,
    private _router: Router,
    private route: ActivatedRoute
) {}

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
        if (params.enrollment) {
            this.enrollment = JSON.parse(params.enrollment);
        }
        this.isNewContact =  params.isNewContact === 'true';
        this.workSession = JSON.parse(params.workSession);
        this.token = params.token;
    });
  }

  reverseFormatDate(date: string) {
    return formatDateToShow(date);
  }

  dateDescription(date: string | undefined, withTime: boolean) {
    if (date == undefined) {
      date = ''
    }
    return getDateDescription(date, withTime);
  }

  async confirm() {
    const response = await this._isfService.saveEnrollmentToWorkSession(
      this.workSession.id,
      this.enrollment,
      this.token,
      this.isNewContact
    );
    if (response != undefined) {
      console.log("Save Enrollment successfully ", response);
      this._router.navigate(['/form-stage-four']);
    }
  }
}
