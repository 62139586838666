import {Injectable} from '@angular/core';
import {Router} from '@angular/router';

import { AngularFireAuth } from '@angular/fire/compat/auth';

import {Observable} from 'rxjs';
import { ErrorSnackbarService } from './error-snackbar.service';
import { throwError } from 'rxjs/internal/observable/throwError';

@Injectable({providedIn: 'root'})
export class AuthService {

    constructor(
        private afAuth: AngularFireAuth,
        private router: Router
    ) {}

    private handleError(error: string) {
        ErrorSnackbarService.showError(error);
        return throwError(() => new Error('Error while login; please try again later.'));
      }

    public async login(email: string, clave: string): Promise<any> {
        try {
            return await this.afAuth.signInWithEmailAndPassword(email, clave);
        } catch (error) {
            this.handleError('Incorrect email and password');
        }
    }

    public isLoginInterceptor(): Observable<string | null>{
        return this.afAuth.idToken;
    }

    public getLoginStatus(): Observable<unknown> {
        return this.afAuth.authState;
    }

    public isLogin(): Promise<boolean> {
        return  new Promise<boolean>((resolve) => {
            this.afAuth.authState.subscribe(res => {
                if (res && res.uid) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            });
        });
    }

    public async logout(): Promise<void> {
        await this.afAuth.signOut();
        this.router.navigate(['/login']);
    }

    public async mailRecupero(mail: string): Promise<void>{
        this.afAuth.sendPasswordResetEmail(mail);
    }
}
