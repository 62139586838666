import { HttpClient, HttpHeaders, HttpErrorResponse} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError } from 'rxjs/internal/operators/catchError';
import { environment } from 'src/environments/environment';
import { ErrorSnackbarService } from './error-snackbar.service';


export interface ProjectIdentifiers {
  id: string,
  name: string
}

export interface Enrollment {
  contact_id: string,
  diet?: string,
  transport?: string,
  location?: string,
  comments?: string,
  contact: {
      name: string,
      lastname: string,
      email: string,
      dni: string,
      phone?: string,
      birthdate?: string,
      pronoun?: string
  }
}

export interface WorkSession {
  id: string,
  implementation_date: string,
  is_sync: boolean,
  name: string,
  registered_participants: number,
  status: string
}

@Injectable({
  providedIn: 'root'
})
export class IsfService {
  private _urlBE: string;
  private _me: any;
  private nameLogin: string;
  private lastNameLogin: string;

  projectsInfo: Record<string, any> = {};
  activeWorkSession = {};

  private _httpOptions: any = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
  };

  constructor(private _http: HttpClient) {
    this._urlBE = environment.config.urlBE;
  }

  async init() {
      try {
        var projects = await this.getAllProjects();
      } catch (error) {
        console.log(error);
      };
      for(let project of projects){
          this.projectsInfo[project['id']] = project;
      }
  }

  private convertToJson(body: Map<string, string>) {
    const json: { [id: string]: string; } = {};
    body.forEach((val, mapKey) => {
      json[mapKey] = val
    })
    return json
  }

  private handleError(error: string) {
    ErrorSnackbarService.showError(error);
    return throwError(() => new Error('Something bad happened; please try again later.'));
  }

  public async getAllProjects(): Promise<any> {
    const url = `${this._urlBE}/projects`;
    return await this._http.get(url, this._httpOptions).toPromise();
  }

  public async syncProjects(): Promise<any> {
      const url = `${this._urlBE}/sync/projects`;
      return await this._http.post(url, this._httpOptions).toPromise()
  }

  public async getAllProjectsIdentifiers(): Promise<ProjectIdentifiers[]> {
    let keys = Object.keys(this.projectsInfo);
    let identifiers: ProjectIdentifiers[] = [];
    for(let key of keys) {
      let projectName: string = this.projectsInfo[key]['name'];
      identifiers.push({id: key, name: projectName});
    }
    return identifiers;
  }

  public async getAllProjectsNames(): Promise<string[]> {
    return Object.keys(this.projectsInfo);
  }

  public async getProjectInfo(id: string): Promise<any> {
    try {
      const url = `${this._urlBE}/projects/${id}`;
      return await this._http.get(url, this._httpOptions).toPromise();
    } catch (error) {
      this.handleError("Error while getting project information");
    }
  }

  public async getAllWorksSessionsByProject(id: string, active: boolean): Promise<any> {
    try {
      const url = `${this._urlBE}/projects/${id}/work-sessions` + (active? '?active=true' : '');
      return await this._http.get(url, this._httpOptions).toPromise();
    } catch (error) {
      this.handleError("Error while getting work sessions");
    }
  }

  public async getAllParticipantsByProject(id: string, updating: boolean = false): Promise<any> {
    try {
      const url = `${this._urlBE}/projects/${id}/participants`;
      if (updating) {
        return await this._http.patch(url, this._httpOptions).toPromise();
      } else {
        return await this._http.get(url, this._httpOptions).toPromise();
      }
    } catch (error) {
      this.handleError("Error while getting participants");
    }
  }

  public async getWorkSessionParticipantsById(id: string): Promise<any> {
    try {
      const url = `${this._urlBE}/work-sessions/${id}/attendance`;
      return await this._http.get(url, this._httpOptions).toPromise();
    } catch (error) {
      this.handleError("Error while getting work session participants");
    }
  }

  public async updateWorkSession(id: string, data: Record<string, any>): Promise<any> {
    try {
      const url = `${this._urlBE}/work-sessions/${id}`;
      return await this._http.patch(url, data, this._httpOptions).toPromise();
    } catch (error) {
      this.handleError("Error while updating work session");
    }
  }

  public async saveWorkSessionAttendance(id: string, attendance: Array<Map<string,string>>, syncCloud:boolean = false): Promise<any> {
    try {
      const url = `${this._urlBE}/work-sessions/${id}/attendance?sync=${syncCloud}`;
      const body = {
        "attendance": attendance.map((att, i, z) =>
          this.convertToJson(att)
        )
      }
      return await this._http.patch(url, body, this._httpOptions).toPromise();
    } catch (error) {
      this.handleError("Error while saving work session attendance");
    }
  }

  public async createWorkSession(projectId: string, name: string, date: string): Promise<any> {
    try {
      const url = `${this._urlBE}/projects/${projectId}/work-sessions`;
      const body = { "name": name, "date": date }
      return await this._http.post(url, body, this._httpOptions).toPromise();
    } catch (error) {
      this.handleError("Error while creating work session");
    }
  }

  public async getWorkSessionInfo(id: string): Promise<any> {
    try {
      const url = `${this._urlBE}/work-sessions/${id}`;
      return await this._http.get(url, this._httpOptions).toPromise();
    } catch (error) {
      this.handleError("Error while getting work session information");
    }
  }

  public setActiveWorkSession(workSession: any) {
    this.activeWorkSession = workSession;
  }

  public getActiveWorkSession(): any {
    return this.activeWorkSession;
  }

  public normalizeWorkSessionName(name: string, urlFriendly: boolean = false): string {
    let splitted = name.split(": ", 2);
    let splittedName = splitted.length > 1? splitted[1] : splitted[0];
    if (urlFriendly) {
      return splittedName.replace('/', '\\');
    }
    return splittedName;
  }

  public async getContactByDni(dni: string, token: string): Promise<any> {
    const url = `${this._urlBE}/contacts/${dni}`;
    try {
      const headers = { headers: this._httpOptions.headers.set('Authorization', 'Bearer ' + token) }
      return await this._http.get(url, headers).toPromise();
    } catch (error) {
      this.handleError("Error while getting contact");
    }
  }

  public async getEnrollmentTokensForProject(id: string): Promise<any> {
    try {
      const url = `${this._urlBE}/projects/${id}/enrollment`;
      return await this._http.get(url, this._httpOptions).toPromise();
    } catch (error) {
      this.handleError("Error while getting enrollment token");
    }
  }

  public async updateEnrollmentForWorkSession(projectId: string, data: Record<string, any>): Promise<any> {
    try {
      const url = `${this._urlBE}/projects/${projectId}/enrollment`;
      return await this._http.patch(url, data, this._httpOptions).toPromise();
    } catch (error) {
      this.handleError("Error updating enrollment");
    }
  }

  public async validateTokenForWorkSessionEnrollment(token: string): Promise<any> {
    try {
      const url = `${this._urlBE}/projects/enrollment/${token}`;
      return await this._http.get(url, this._httpOptions).toPromise();
    } catch (error) {
      this.handleError("Error while validating token");
    }
  }

  public async saveEnrollmentToWorkSession(id: string, enrollment: Enrollment, token: string, isNewContact:boolean = false): Promise<any> {
    let body;
    if (isNewContact) {
      body = JSON.stringify(enrollment);
    } else {
      const keysToInclude = ['contact_id', 'diet', 'transport', 'location', 'comments'];

      const filteredEnrollment = Object.keys(enrollment).reduce((obj, key) => {
        if (keysToInclude.includes(key)) {
          obj[key] = enrollment[key as keyof Enrollment];
        }
        return obj;
      }, {} as Record<string, any>);

      body = JSON.stringify(filteredEnrollment);
    }

    try {
      const url = `${this._urlBE}/work-sessions/${id}/attendance`;
      const headers = { headers: this._httpOptions.headers.set('Authorization', 'Bearer ' + token) }
      return await this._http.post(url, body, headers).toPromise();
    } catch (error) {
      this.handleError("Error while saving enrollment");
    }
  }

  public async addParticipantToWorkSession(id: string, contact: string): Promise<any> {
    let body: Record<string, string> = {contact_id: contact};
    try {
      const url = `${this._urlBE}/work-sessions/${id}/attendance`;
      return await this._http.post(url, body, this._httpOptions).toPromise();
    } catch (
      error) {
      this.handleError("Error while adding participant to work session");
    }
  }

  public async updateParticipant(id: string, data: Record<string, any>): Promise<any> {
    try {
      const url = `${this._urlBE}/participants/${id}`;
      return await this._http.patch(url, data, this._httpOptions).toPromise();
    } catch (error) {
      this.handleError("Error while updating participant");
    }
  }

}
