import { Component } from '@angular/core';

@Component({
  selector: 'form-stage-four',
  template: `
    <mat-card class="final-message">
        <h3>Te registraste exitosamente.</h3>
        <h3>¡Gracias por sumarte! ¡Aguarda con atención, el viernes enviamos la logística para ir a la jornada!</h3>
        <h3>Ya podes cerrar esta ventana</h3>
    </mat-card>
  `,
})
export class WorkSessionFormStageFourComponent {}
