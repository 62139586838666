import { Component, OnInit } from '@angular/core';
import { Navigation } from 'src/app/model/navigation';
import { AuthService } from 'src/app/services/auth.service';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
  selector: 'sidenav',
  template: `
    <mat-toolbar *ngIf="isMobile" class="toolbar">
      <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon" (click)="onClickHandler()">
        <mat-icon class="menu-icon">menu</mat-icon>
      </button>
      <div class="logo">
        <img class="logo-icon" src="assets/images/logos/isf.png">
        <span class="logo-text secondary-text">ISF-Ar</span>
      </div>
    </mat-toolbar>
    <mat-sidenav-container class="container">
      <mat-sidenav mode="side" opened='{{isOpenedMenu}}' class="sidenav-container mat-elevation-z8">
        <div class="navbar-top" *ngIf="!isMobile">

          <div class="logo">
            <img class="logo-icon" src="assets/images/logos/isf.png">
            <span class="logo-text secondary-text">ISF-Ar</span>
          </div>

        </div>

        <mat-divider></mat-divider>

        <div class="navbar-scroll-container">
            <ng-container *ngFor="let item of navigation">
                <navigation-item (childEvent)="childrenEventHandler($event)" [item]="item"></navigation-item>
            </ng-container>
          </div>
      </mat-sidenav>

      <!-- CONTENT -->
      <mat-sidenav-content>
        <div class="content">
          <router-outlet></router-outlet>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
  `,
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {
  isLoggedIn = false;
  navigation: Navigation[] = [];
  isMobile = false;
  isOpenedMenu = false;
  constructor(private _navigationService: NavigationService, private authService: AuthService) {
      this.isMobile = window.innerWidth < 768;
      this.isOpenedMenu = !this.isMobile;
  }

  async ngOnInit(): Promise<void> {
    this.authService.getLoginStatus().subscribe(async (user) => {
      let castUser = user as {uid: string} | null
      this.isLoggedIn = !!castUser?.uid
      if (this.isLoggedIn) {
        await this._navigationService.init();
        this.navigation = this._navigationService.getNavigation();
      }
    });
  }

  onClickHandler() {
    this.isOpenedMenu = !this.isOpenedMenu;
  }

  childrenEventHandler(event: any) {
    this.isOpenedMenu = false;
  }
}
