import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { LoginComponent } from './components/login/login.component';
import { ProjectContainerComponent } from './components/project-container/project-container.component';
import { ProjectInfoComponent } from './components/project-info/project-info.component';
import { WorkSessionFormComponent } from './components/work-session-form/work-session-form.component';
import { WorkSessionFormStageOneComponent } from './components/work-session-form/work-session-form-stage-one.component';
import { WorkSessionFormStageTwoComponent } from './components/work-session-form/work-session-form-stage-two.component';
import { WorkSessionFormStageThreeComponent } from './components/work-session-form/work-session-form-stage-three.component';
import { WorkSessionFormStageFourComponent } from './components/work-session-form/work-session-form-stage-four.component';
import { WorkSessionComponent } from './components/work-session/work-session.component';
import { AuthGuardService } from './services/auth-guard.service';
import { IsSignedInGuard } from './services/sign-in-guard.service';
import { SidenavComponent } from './components/sidenav/sidenav.component';

const routes: Routes = [
  { path: '', redirectTo: '/cora/projects', pathMatch: 'full'},
  { path: 'cora',
    component: SidenavComponent,
    canActivate: [AuthGuardService],
    children: [
      { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuardService]},
      { path: 'projects', component: ProjectContainerComponent, canActivate: [AuthGuardService]},
      { path: 'project/:name/:id', component: ProjectInfoComponent, canActivate: [AuthGuardService]},
      { path: 'work-session/:workSessionName/:workSessionId', component: WorkSessionComponent, canActivate: [AuthGuardService]},
    ]
  },
  { path: 'login', component: LoginComponent, canActivate: [IsSignedInGuard]},
  { path: 'work-session-form/:token', component: WorkSessionFormComponent},
  { path: 'form-stage-one', component: WorkSessionFormStageOneComponent},
  { path: 'form-stage-two', component: WorkSessionFormStageTwoComponent},
  { path: 'form-stage-three', component: WorkSessionFormStageThreeComponent},
  { path: 'form-stage-four', component: WorkSessionFormStageFourComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
