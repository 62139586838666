import {Component, ElementRef, ViewChild, ViewEncapsulation} from '@angular/core';
import { Enrollment, IsfService, WorkSession } from '../../services/isf.service';
import { ActivatedRoute, Router } from '@angular/router';

import { getDateDescription } from 'src/app/utils/utils';

@Component({
    selector: 'work-session-form',
    template: `
        <div id="work-session-form" class="page-layout carded fullwidth inner-scroll">
            <div class="banner">
                <img id="isf-banner" src="assets/images/ISF-banner.jpeg" alt="">
            </div>
            <div class="form-container" *ngIf="enrollmentActive">
                    <div class="introduction-text">
                        <h3>Te damos la bienvenida al formulario de inscripción a la jornada "{{this.workSessionName}}" del día {{this.workSessionDate}} del Proyecto {{this.projectName}} de Ingeniería Sin Fronteras Argentina.
                            Una vez validado tu DNI te solicitaremos algunos datos para registrarte.
                        </h3>
                    </div>

                    <mat-card class="form-card">
                        <h2>{{this.projectName}}</h2>
                        <p>Compartinos tu DNI para anotarte en la jornada</p>
                        <p class="danger-text">Antes de continuar, verifica que sea correcto!</p>
                        <form class="form-page-dni">
                            <mat-form-field class="dni-full-width">
                                <input matInput id="dni" placeholder="DNI" inputmode="numeric" #dni>
                            </mat-form-field>
                            <div class="dni-button">
                                <button id="btnValidate" class="form-button" mat-flat-button (click)="getContactByDni()">Validar</button>
                            </div>
                        </form>
                    </mat-card>
            </div>
          <div class="form-container" *ngIf="!enrollmentActive">
            <div class="introduction-text">
              <h3>Gracias por querer inscribirte.</h3>
              <h4>Esta jornada ya cerró su inscripción. Por favor, contactate con tu coordinador o coordinadora.</h4>
            </div>
          </div>

        </div>`,
    styleUrls: ['./work-session-form.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class WorkSessionFormComponent {
    @ViewChild('dni') dni: ElementRef;

    enrollmentActive: boolean = true;

    workSession: WorkSession;
    workSessionName: string;
    workSessionDate: string;
    projectName = null;

    validDni: boolean = false;
    token: string | null;

    constructor(
          private _isfService: IsfService,
          private _router: Router,
          private activatedRoute: ActivatedRoute
      ) {
      }

    ngOnInit() {
        const token = this.activatedRoute.snapshot.paramMap.get('token');
        if (token) {
            this._isfService.validateTokenForWorkSessionEnrollment(token).then(
                (response) => {
                    this.projectName = response.project.name;
                    this.workSession =  response.work_session;
                    this.workSessionName = this.workSession.name;
                    this.workSessionDate = getDateDescription(this.workSession.implementation_date, true)
                }
            ).catch((error: any) => {
                this.enrollmentActive = false;
                console.error(error);
            });
        }
    }

    ngAfterViewInit() {
        this.validateDni();
    }

    validateDni() {
        return this.validDni;
    }


    hideSensitiveInfo(value: string, visibleLength: number): string {
        if (visibleLength >= value.length) {
          return value;
        }

        const hiddenLength = value.length - visibleLength;
        const hiddenChars = '*'.repeat(hiddenLength);
        const visibleChars = value.substring(value.length - visibleLength);

        return `${hiddenChars}${visibleChars}`;
    }

    hideSensitiveEmail(value: string): string {
        const atIndex = value.indexOf('@'); // Get the position of the "@" symbol
        if (atIndex === -1) {
            return value; // If the "@" symbol is not found, return the original value
        }
        const visibleLength = Math.min(3, atIndex); // Number of visible characters before the "@" symbol
        const hiddenLength = value.length - atIndex - 1; // Number of hidden characters after the "@" symbol
        const hiddenChars = '*'.repeat(hiddenLength);
        return `${value.substring(0, visibleLength)}${hiddenChars}${value.substring(atIndex)}`;
    }

    mapContactToEnrollment(contact: any): Enrollment {
        return {
            contact_id: contact.id,
            contact: {
                name: contact.name,
                lastname: contact.lastname,
                dni: this.hideSensitiveInfo(contact.dni, 3),
                email: this.hideSensitiveEmail(contact.email),
                phone: contact.phone? this.hideSensitiveInfo(contact.phone, 3) : undefined,
                birthdate: contact.birthdate ? this.getBirthdate(contact.birthdate) : undefined,
                pronoun: contact.pronoun ? contact.pronoun : undefined,
            }
        }
    }

    getBirthdate(birthdate: string) {
        return birthdate.split(' ')[0];
    }

    getContactByDni() {
        const token = this.activatedRoute.snapshot.paramMap.get('token');
        if(token) {
            let formatedDni = this.dni.nativeElement.value.replace(/[^0-9]/g, '')

            this._isfService.getContactByDni(formatedDni, token).then((response: any) => {
                if (Object.keys(response).length > 0) {
                    this._router.navigate(['/form-stage-one'], { queryParams: { enrollment: JSON.stringify(this.mapContactToEnrollment(response.contact)),
                        dni: formatedDni,
                        projectName: this.projectName,
                        workSession: JSON.stringify(this.workSession),
                        isNewContact: false,
                        token: token
                    }});
                } else {
                    this._router.navigate(['/form-stage-one'], { queryParams: { entollment: JSON.stringify({}), dni: formatedDni,
                    projectName: this.projectName,
                    workSession: JSON.stringify(this.workSession),
                    isNewContact: true,
                    token: token
                    }});
                }
            }
            ).catch((error: any) => {
                console.log(error);
            });
        } else {
            console.log('Token not found');
        }
    }
}
