import { Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';
import { NavigationItem } from 'src/app/model/navigation';

@Component({
  selector: 'navigation-item',
  template:`
    <!-- item.url -->
    <a *ngIf="item.type == 'item' && item.url" class="nav-link" [ngClass]="item.classes ?? ''" 
      [routerLink]="item.url" [target]="item.openInNewTab ? '_blank' : '_self'" (click)="onClickHandler($event)">
      <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <a *ngIf="item.type == 'collapsable'" class="nav-link" 
       (click)="toggleOpen($event)"
       [routerLink]="[item.url]" [routerLinkActive]="['active', 'accent']"
       [routerLinkActiveOptions]="{exact: item.exactMatch || false}"
       [target]="item.openInNewTab ? '_blank' : '_self'">
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <div *ngIf="showChildren" class="children">
        <ng-container *ngFor="let item of item.children">
            <navigation-item (childEvent)="eventHandler()" [item]="item"></navigation-item>
        </ng-container>
    </div>

    <ng-template #itemContent>
        <mat-icon class="nav-link-icon" *ngIf="item.icon">{{item.icon}}</mat-icon>
        <span class="nav-link-title" [translate]="item.translate">{{item.title}}</span>
        <span class="nav-link-badge" *ngIf="item.badge" [translate]="item.badge.translate"
          [ngStyle]="{'background-color': item.badge.bg, 'color': item.badge.fg}">
            {{item.badge.title}}
        </span>
    </ng-template>

  `,
  styleUrls: ['./navigation-item.component.scss']
})
export class NavigationItemComponent implements OnInit {

  @Input() item: NavigationItem;
  @Output() childEvent = new EventEmitter<void>();
  showChildren: boolean = false;
  // Todo: hacer output para que no muestren los hijos los navigation items collapsables
  ngOnInit(): void {}

  toggleOpen(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.showChildren = !this.showChildren;
  }

  onClickHandler(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.childEvent.emit();
  }

  eventHandler(): void {
    this.childEvent.emit();
  }
}
