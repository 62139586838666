import { Component, ViewChild, ElementRef } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import { Enrollment, IsfService, WorkSession } from 'src/app/services/isf.service';
import {formatDate, formatDateToShow} from 'src/app/utils/utils';

export interface Pronoun {
    value: string;
    viewValue: string;
}

@Component({
  selector: 'form-stage-one',
  template: `
    <div class="introduction-text">
        <h3>Los siguientes datos nos permiten gestionar tu seguro. Los mismos no serán compartidos.</h3>
    </div>
    <mat-card class="form-card">
        <h2>Datos personales</h2>
        <form class="form-page">
            <input class="form-input" type="text" [placeholder]="enrollment && enrollment.contact.name ? enrollment.contact.name : 'Nombre'" [disabled]="enrollment && enrollment.contact.name" #name [ngClass]="{'input-error': nameError}">
            <div class="error-message" *ngIf="nameError">Ingrese un nombre válido.</div>

            <input class="form-input" type="text" [placeholder]="enrollment && enrollment.contact.lastname ? enrollment.contact.lastname : 'Apellido'" [disabled]="enrollment && enrollment.contact.lastname" #lastname [ngClass]="{'input-error': lastnameError}">
            <div class="error-message" *ngIf="lastnameError">Ingrese un apellido válido.</div>

            <input class="form-input" type="text" [placeholder]=dniValue [disabled]="true" #dni [ngClass]="{'input-error': dniError}">
            <div class="error-message" *ngIf="dniError">Ingrese un DNI válido.</div>

            <input class="form-input" type="text" *ngIf="!isBirthdateUndefined" [placeholder]="enrollment.contact.birthdate ? formatDateToShow(enrollment.contact.birthdate) : ''" [disabled]="!isBirthdateUndefined" #birthdate>
            <mat-form-field *ngIf="isBirthdateUndefined">
                <input matInput readonly #birthdate [matDatepicker]="picker" placeholder="Fecha de nacimiento">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker touchUi #picker></mat-datepicker>
            </mat-form-field>
            <div class="error-message" *ngIf="birthdateError">Ingrese una fecha válida.</div>

            <input class="form-input" type="text" [placeholder]="enrollment && enrollment.contact.email ? enrollment.contact.email : 'Email'" [disabled]="!isNewContact" #email [ngClass]="{'input-error': emailError}">
            <div class="error-message" *ngIf="emailError">Ingrese un email válido.</div>

            <input class="form-input" type="text" [placeholder]="enrollment && enrollment.contact.phone ? enrollment.contact.phone : 'Phone'" [disabled]="!isNewContact" #phone [ngClass]="{'input-error': phoneError}">
            <div class="error-message" *ngIf="phoneError">Ingrese un número de teléfono válido.</div>

            <input class="form-input" type="text" *ngIf="!isPronounUndefined" [placeholder]="enrollment && enrollment.contact.pronoun ? enrollment.contact.pronoun : 'Pronoun'" [disabled]="!isNewContact" #pronoun>
            <mat-form-field class="input-select" *ngIf="isPronounUndefined">
                <mat-label>Pronombre que utilizas</mat-label>
                <mat-select (selectionChange)="onPronounSelectionChange($event)">
                    <mat-option *ngFor="let pronoun of pronouns" [value]="pronoun.value">
                    {{pronoun.viewValue}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </form>
        <p>{{ isNewContact ? 'Si más adelante' : 'Si faltan o '}} queres modificar alguno de estos datos comunicate con:</p>
        <p>voluntariado@isf-argentina.org</p>
    </mat-card>

    <div class="confirm-button">
        <button mat-flat-button class="form-button" (click)='processForm()'>Continuar</button>
    </div>
  `,
})
export class WorkSessionFormStageOneComponent {
    @ViewChild("name") name: ElementRef;
    @ViewChild("lastname") lastname: ElementRef;
    @ViewChild("dni") dni: ElementRef;
    @ViewChild("birthdate") birthdate: ElementRef;
    @ViewChild("email") email: ElementRef;
    @ViewChild("phone") phone: ElementRef;
    @ViewChild("pronoun") pronoun: ElementRef;

    enrollment: Enrollment;
    dniValue: string;
    isNewContact: boolean;
    token: string;
    isPronounUndefined: boolean = true;
    isBirthdateUndefined: boolean = true;
    selectedPronoun: string;
    workSession: WorkSession;
    projectName = null;

    nameError = false;
    lastnameError = false;
    dniError = false;
    birthdateError = false;
    emailError = false;
    phoneError = false;

    pronouns: Pronoun[] = [
        {value: 'ella/la', viewValue: 'ella/la'},
        {value: 'él/lo', viewValue: 'él/lo'},
        {value: 'elle/le', viewValue: 'elle/le'}
    ];

    constructor(
        private _isfService: IsfService,
        private _router: Router,
        private route: ActivatedRoute
    ) {}

    ngOnInit() {
        this.route.queryParams.subscribe(params => {
            if (params.enrollment) {
                this.enrollment = JSON.parse(params.enrollment);
                this.isPronounUndefined = this.enrollment.contact.pronoun? false : true;
                this.isBirthdateUndefined = this.enrollment.contact.birthdate? false : true;
            }
            this.dniValue = params.dni;
            this.projectName = params.projectName;
            this.workSession = JSON.parse(params.workSession);
            this.isNewContact = params.isNewContact === 'true';
            this.token = params.token;
        });

    }

    formatDateToShow(date: string) {
        return formatDateToShow(date);
      }

    private addErrorClass(inputElement: HTMLInputElement) {
        inputElement.classList.add('input-error');
      }

    onPronounSelectionChange(event: MatSelectChange) {
        this.selectedPronoun = event.value;
    }

    isValidDate() {
      const alreadyLoadedDate = this.enrollment && this.enrollment.contact.birthdate
      if (alreadyLoadedDate) {
        return true;
      }

      let dateToValidate = this.getBirthdateString();

      const dateRegex = /^([1-9]|0[1-9]|1[0-9]|2[0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;
      return dateToValidate != '' && !dateRegex.test(dateToValidate)
    }

    getBirthdateString() {
      if (this.enrollment && this.enrollment.contact.birthdate) {
        return this.enrollment.contact.birthdate
      }
      let auxDate = (this.birthdate.nativeElement.value.toString()).replaceAll('-', '/')
      return formatDate(auxDate);
    }

    validForm() {
        const nameValue = this.enrollment && this.enrollment.contact.name ? this.enrollment.contact.name : this.name.nativeElement.value;
        const lastnameValue = this.enrollment && this.enrollment.contact.lastname ? this.enrollment.contact.lastname : this.lastname.nativeElement.value;
        const emailValue = this.enrollment && this.enrollment.contact.email ? this.enrollment.contact.email : this.email.nativeElement.value;
        const phoneValue = this.enrollment && this.enrollment.contact.phone ? this.enrollment.contact.phone : this.phone.nativeElement.value;

        if (nameValue.trim() === '') {
            this.nameError = true;
            this.addErrorClass(this.name.nativeElement);
            return false;
        }

        if (lastnameValue.trim() === '') {
            this.lastnameError = true;
            this.addErrorClass(this.lastname.nativeElement);
            return false;
        }

        if (this.isNewContact) {
            if (this.dniValue.trim() === '' || /\D/.test(this.dniValue)) {
                this.dniError = true;
                this.addErrorClass(this.dni.nativeElement);
                return false;
            }

            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (emailValue.trim() === '' || !emailRegex.test(emailValue)) {
                this.emailError = true;
                this.addErrorClass(this.email.nativeElement);
                return false;
            }

            if (!this.isValidDate()) {
                this.birthdateError = true;
                this.addErrorClass(this.birthdate.nativeElement);
                return false;
            }

            if (phoneValue.trim() === '' || /\D/.test(phoneValue)) {
                this.phoneError = true;
                this.addErrorClass(this.phone.nativeElement);
                return false;
            }
        }
        return true;
    }

    processForm() {
        if(this.validForm()) {
            this.nameError = false;
            this.lastnameError = false;
            this.dniError = false;
            this.emailError = false;
            this.phoneError = false;

            const processedContact = {
                contact_id: this.enrollment && this.enrollment.contact_id? this.enrollment.contact_id : undefined,
                contact: {
                    name: this.enrollment && this.enrollment.contact.name? this.enrollment.contact.name : this.name.nativeElement.value,
                    lastname: this.enrollment && this.enrollment.contact.lastname? this.enrollment.contact.lastname : this.lastname.nativeElement.value,
                    dni: this.dniValue,
                    birthdate: this.getBirthdateString(),
                    email: this.enrollment && this.enrollment.contact.email? this.enrollment.contact.email : this.email.nativeElement.value,
                    phone: this.enrollment && this.enrollment.contact.phone? this.enrollment.contact.phone : this.phone.nativeElement.value,
                    pronoun: this.enrollment && this.enrollment.contact.pronoun? this.enrollment.contact.pronoun : this.selectedPronoun
                }
            }
            this._router.navigate(['/form-stage-two'], { queryParams: { enrollment: JSON.stringify(processedContact),
                projectName: this.projectName,
                workSession: JSON.stringify(this.workSession),
                isNewContact: this.isNewContact,
                token: this.token
            } });
        }
    }
}
