import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

export interface User {
  email: string;
  password: string;
}

@Component({
  selector: 'login',
  template: `
    <div class="login-container">
      <div class="banner">
          <img id="isf-banner" src="assets/images/ISF-banner.jpeg" alt="">
      </div>
      <mat-card class="login-content-container">
          <mat-card-title>
              <b>LOGIN</b>
          </mat-card-title>
          <div class="card-body p-4">
              <mat-form-field appearance="outline">
                <mat-label>Email</mat-label>
                <input matInput type="email" placeholder="Ingrese su email" [(ngModel)]="user.email">
                <mat-icon matSuffix>mail</mat-icon>
              </mat-form-field>
              <mat-form-field appearance="outline">
                <mat-label>Password</mat-label>
                <input matInput type="password" placeholder="Ingrese su password" [(ngModel)]="user.password">
                <mat-icon matSuffix>vpn_key</mat-icon>
              </mat-form-field>
              <button mat-button class="btn" (click)="login()">Login</button>
          </div>
      </mat-card>
    </div>
  `,
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  user: User = {
    email: '',
    password: ''
  };

  constructor(private authService: AuthService, private router: Router) {}

  login() {
    const { email, password } = this.user;
    this.authService.login(email, password).then(res => {
      this.router.navigate(['/cora/projects']);
    });
  }

}
