import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dashboard',
  template: `
    <p>
      dashboard works!
    </p>
  `,
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
