import { Component, OnInit } from '@angular/core';
import { IsfService } from 'src/app/services/isf.service';
import { getDateDescription } from 'src/app/utils/utils';
import { Router } from '@angular/router';

export interface ProyectsElement {
    name: string;
    province: string;
    location: string;
    status: string;
    dateInit: string;
    participants: number;
  }

@Component({
  selector: 'project-container',
  template: `
    <div id="projects-component" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent"
            fxLayout="column" fxLayoutAlign="center center"
            fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between center">

            <!-- BREADCRUMB -->
            <div class="breadcrumb">
              <mat-icon class="breadcrumb-icon">home</mat-icon>
              <a [routerLink]="['/cora/projects']" class="breadcrumb-link">Proyectos</a>
            </div>
            <!-- / BREADCRUMB -->

        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div  *ngIf="dataSource" class="content-card">
            <div class="sync-container">
              <mat-icon>cloud</mat-icon>
              <div>Actualizar proyectos desde SalesForce </div>
              <button mat-button class="update-button" (click)="onSyncProjects()">Actualizar</button>
            </div>

            <table mat-table [dataSource]="dataSource" class="projects-table mat-elevation-z8">

                <!--- Note that these columns can be defined in any order.
                      The actual rendered columns are set as a property on the row definition" -->

                <!-- Name Column -->
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef> Nombre </th>
                    <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                </ng-container>

                <!-- location Column -->
                <ng-container matColumnDef="location">
                    <th mat-header-cell *matHeaderCellDef> Ubicación </th>
                    <td mat-cell *matCellDef="let element"> {{element.location}} </td>
                </ng-container>

                <!-- status Column -->
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef> Estado </th>
                    <td mat-cell *matCellDef="let element"> {{element.status}} </td>
                </ng-container>

                <!-- dateInit Column -->
                <ng-container matColumnDef="date_init">
                    <th mat-header-cell *matHeaderCellDef> Fecha de inicio </th>
                    <td mat-cell *matCellDef="let element"> {{projectDateInit(element.date_init)}} </td>
                </ng-container>

                <!-- participants Column -->
                <ng-container matColumnDef="participants">
                    <th mat-header-cell *matHeaderCellDef> Participantes </th>
                    <td mat-cell *matCellDef="let element"> {{element.participants}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row class="clickeable" *matRowDef="let row; columns: displayedColumns;" (click)="onClickHandler(row)"></tr>
            </table>

        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->

    </div>
  `,
  styleUrls: ['./project-container.component.scss']
})
export class ProjectContainerComponent implements OnInit {
  displayedColumns: string[] = ['name', 'location', 'status', 'date_init', 'participants'];
  dataSource = [];

  constructor(
    private _isfService: IsfService,
    private _router: Router
  ) { }

  async ngOnInit() {
    const data = await this._isfService.getAllProjects();
    this.dataSource = this.sortProjects(data);
  }

  projectDateInit(dateInit: string) {
    return getDateDescription(dateInit);
  }

  sortProjects(projects: any) {
    return projects.sort((a: { name: string; }, b: { name: string; }) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  }

  onSyncProjects() {
    this._isfService.syncProjects().then(
      (response) => {
        this._isfService.getAllProjects().then(
          (projects) => {
            this.dataSource = this.sortProjects(projects)
          }
        ).catch((error: any) => {
          console.error(error);
        })
      }
    ).catch((error: any) => {
      console.error(error);
    })
  }

  onClickHandler(row: any) {
    const projectId = row.id;
    let projectName = this._isfService.normalizeWorkSessionName(row.name, true);
    this._router.navigate(["/cora/project/" + projectName + "/" + projectId]);
  }
}
