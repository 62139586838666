import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { switchMap, take } from 'rxjs/operators';
import { JsonPipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) { }
  
  
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.authService.isLoginInterceptor().pipe(
      take(1),
      switchMap(idToken => {
          if (idToken) {
            let cloneRequest = request.clone({headers: request.headers.set('Authorization', 'Bearer ' + idToken)});
            return next.handle(cloneRequest);
          }
          return next.handle(request);         
      })
  );
  }
}
