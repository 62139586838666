export function getDateDescription(date: string, withTime: boolean=false) {
  if (date == null || date == '' || date == ' ') {
    return '-'
  }
  const auxDate = new Date(date);
  let dateDescription = `${auxDate.getDate()}/${auxDate.getMonth() + 1}/${auxDate.getFullYear()}`

  if (withTime) {
    return dateDescription + ` ${inTwoDigits(auxDate.getHours())}:${inTwoDigits(auxDate.getMinutes())}`;
  }
  return dateDescription;
}

function inTwoDigits(value: any) {
  return String(value).padStart(2, '0');
}

export function formatDate(inputDate: string) {
  const parts = inputDate.split('/');
  const day = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10);
  const year = parseInt(parts[2], 10);

  const date = new Date(year, month - 1, day);
  if (Number.isNaN(date.getTime())) {
    return ''
  }
  const formattedYear = date.getFullYear();
  const formattedMonth = String(date.getMonth() + 1).padStart(2, '0');
  const formattedDay = String(date.getDate()).padStart(2, '0');
  return `${formattedYear}-${formattedMonth}-${formattedDay}`;
}

export function formatDateToShow(formattedDate: string) {
  const parts = formattedDate.split('-');
  const year = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10);
  const day = parseInt(parts[2], 10);
  return `${day}/${month}/${year}`;
}

export function getDateStringForBackend(date: Date) {
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
}
